<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2 col-12">
        <b-row>
          <!-- Right Col: Table -->
          <b-col
            cols="9"
            xl="9"
          >
            <span class="font-weight-bold">{{ $t(elementName + ".section.view") }} </span>
          </b-col>
        </b-row>
        <br>

      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="12"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <span class="p-1"><svg-icon
                  type="mdi"
                  :path="mdiAccountCard"
                /></span>
                <span class="font-weight-bold">{{ $t(elementName +'.object.consult_type') }}</span>
              </th>
              <td class="pb-50">
                <template v-if="elementData.order">
                  {{ elementData.order.next_consult ? elementData.order.next_consult.value : '' }}
                </template>
                <template v-else>
                  {{ elementData.next_consult ? elementData.next_consult.value : '' }}
                </template>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="p-1"><svg-icon
                  type="mdi"
                  :path="mdiFileDocumentCheck"
                /></span>
                <span class="font-weight-bold">{{ $t(elementName +'.object.name') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="p-1"><svg-icon
                  type="mdi"
                  :path="mdiCalendarRange"
                /></span>
                <span class="font-weight-bold">{{ $t(elementName +'.object.date') }}</span>
              </th>
              <td class="pb-50">
                {{ formatDate(elementData.date) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="p-1"><svg-icon
                  type="mdi"
                  :path="mdiCheckUnderlineCircle"
                /></span>
                <span class="font-weight-bold">{{ $t(elementName +'.object.result') }}</span>
              </th>
              <td class="pb-50">
                {{ getResult(elementData) }}

              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="p-1"><svg-icon
                  type="mdi"
                  :path="mdiChartBoxPlusOutline"
                /></span>
                <span class="font-weight-bold">{{ $t(elementName +'.object.value') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.value }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="p-1"><svg-icon
                  type="mdi"
                  :path="mdiScaleBalance"
                /></span>
                <span class="font-weight-bold">{{ $t(elementName +'.object.unit') }}</span>
              </th>
              <td class="pb-50">
                {{ getUnit(elementData) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="p-1"><svg-icon
                  type="mdi"
                  :path="mdiHospitalBuilding"
                /></span>
                <span class="font-weight-bold">{{ $t(elementName +'.object.provider') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.provider ? elementData.provider.name : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="p-1"><svg-icon
                  type="mdi"
                  :path="mdiCheckboxMarkedCircle"
                /></span>
                <span class="font-weight-bold">{{ $t(elementName +'.object.made_by_avenir') }}</span>
              </th>
              <td class="pb-50">
                <span v-if="elementData.made_by_avenir"> {{ $t('boolean.yes') }}</span>
                <span v-else> {{ $t('boolean.no') }} </span>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="p-1"><svg-icon
                  type="mdi"
                  :path="mdiFileSign"
                /></span>
                <span class="font-weight-bold">{{ $t(elementName +'.object.report') }}</span>
              </th>
              <td class="pb-50">
                <span
                  v-if="elementData.report"
                  class="file-class"
                >
                  <a
                    id="view-report-button"
                    class="btn mb-1 mb-sm-0 mr-0 mr-sm-1 btn-secondary"
                    :href="getUrl(elementData.report)"
                    target="_blank"
                  >
                    {{ $t(elementName + ".object.report") }}
                  </a>
                </span>
                <span
                  v-else
                  class="file-class"
                >
                  Sin reporte
                </span>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="p-1"><svg-icon
                  type="mdi"
                  :path="mdiText"
                /></span>
                <span class="font-weight-bold">{{ $t(elementName+'.object.notes') }}</span>
              </th>
              <td class="pb-50">
                {{ getNotes(elementData) }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
          {{ $t('actions.back') }}
        </b-button>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import moment from 'moment'

import SvgIcon from '@jamescoyle/vue-icon'
import {
  mdiAccountCard, mdiFileDocumentCheck, mdiCalendarRange, mdiCheckUnderlineCircle,
  mdiChartBoxPlusOutline, mdiScaleBalance, mdiHospitalBuilding, mdiCheckboxMarkedCircle,
  mdiFileSign, mdiText,
} from '@mdi/js'

import router from '@/router'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BCardHeader, BCardBody, SvgIcon,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    elementName: {
      type: String,
      required: true,
    },
    caseId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    router() {
      return router
    },
  },
  setup() {
    return {
      mdiAccountCard,
      mdiFileDocumentCheck,
      mdiCalendarRange,
      mdiCheckUnderlineCircle,
      mdiChartBoxPlusOutline,
      mdiScaleBalance,
      mdiHospitalBuilding,
      mdiCheckboxMarkedCircle,
      mdiFileSign,
      mdiText,
      formatDate(field) {
        return field ? moment(field, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
      },
      getUnit(item) {
        if (!item || !item.unit) {
          return 'NA'
        }
        return item.unit.key
      },
      getResult(item) {
        if (!item || !item.result) {
          return 'NA'
        }
        return item.result.value
      },
      getNotes(item) {
        if (!item || !item.notes) {
          return 'Ninguna'
        }
        return item.notes
      },
      getUrl(item) {
        if (!item || !item.path) {
          return ''
        }
        let path = item.path.split(/[\/,\\]+/)
        path = path[path.length - 1]
        return `${process.env.VUE_APP_BASE_API}/api/v1/digital_file/digital_file/record/${path}`
      },
    }
  },
}
</script>

<style>

</style>
