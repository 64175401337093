<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_data') }}
      </h4>
    </b-alert>
    <template v-if="elementData">
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <element-info-card
            v-if="elementData"
            :element-data="elementData"
            :element-name="RESOURCES_ELEMENT_NAME"
            :case-id="caseId"
          />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BButton, BAlert, BLink,
} from 'bootstrap-vue'
import ElementStoreModule from '@/views/case/labtests/storeModule'
import ElementInfoCard from './InfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BLink,
    // Local Components
    ElementInfoCard,
  },
  data() {
    return {
      elementData: undefined,
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      caseId: undefined,
    }
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'labtests'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-mh-v-${this.RESOURCES_ELEMENT_NAME}`

    this.caseId = router.currentRoute.params.case_id

    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    await this.fetchElement()
    await this.fetchReport()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    fetchElement() {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_element`, { id: router.currentRoute.params.id })
        .then(response => {
          console.info(response)
          this.elementData = response.data
          console.info(this.elementData)
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.elementData = undefined
          }
        })
    },
    fetchReport() {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchDocuments`, { prst_uuid: this.prst_uuid })
        .then(response => {
          console.log(response.data)
          this.elementData.report = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.elementData = undefined
          }
        })
    },
    getName(item) {
      console.log(item)
      let { name } = item
      if (item.documental_type && item.documental_type.name) name = `${item.documental_type.name} - ${item.name}`
      return name
    },
  },
}
</script>

<style>

</style>
